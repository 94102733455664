import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'angly-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /* Variables */
  contact: any;

  lat = 30.67995;
  lng = 76.72211;

  constructor(private pageTitleService: PageTitleService, private service: ChkService, private db: AngularFirestore) {

    /* Page title */
    this.pageTitleService.setTitle(' Lets Get In Touch ');

    /* Page subTitle */
    this.pageTitleService.setSubTitle(' Available anytime you need us!');

    this.db.doc('pages/contact').get().toPromise().then(contact => {
      this.contact = contact.data();
    }).catch(err => {
      console.error(err.message);
    });
  }

  ngOnInit() {
  }

}
