import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductDialogComponent } from '../product_dialog/product_dialog.component';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'angly-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  /* Variables */
  product: {};
  type: string;


  constructor(private service: ChkService, private db: AngularFirestore, private route: ActivatedRoute, private dialog: MatDialog) {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('id');
      this.db.doc('types/' + this.type).get().toPromise().then(product => {
        this.product = product.data();
        console.log(this.product);
      }).catch(err => {
        console.error(err.message);
      });
    });
  }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

}
