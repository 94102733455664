/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

  sendMessageForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private db: AngularFirestore) {

    this.sendMessageForm = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      textarea: [null, [Validators.required]]
    });
  }

  ngOnInit() {
  }

  /*
   * sendMessage
   */
  sendMessage(values: any) {
    if (this.sendMessageForm.valid) {
      this.db.doc('settings/contacts').get().toPromise().then(emails => {
        let mails = [];
        if (emails.exists) {
          mails = emails.data().contacts;
        }
        mails.push(values);
        const data = { contacts: mails };
        this.db.doc('settings/contacts').set(data).then(d => {
          console.log('saved successfully');
        }).catch(err => {
          console.error(err.message);
        });
      }).catch(err => {
        console.error(err.message);
      });
    } else {
      console.log('Error!');
    }
  }

}
