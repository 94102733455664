import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'product_dialog',
    templateUrl: './product_dialog.component.html',
    styleUrls: ['./product_dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class ProductDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private db: AngularFirestore,
        private cdRef: ChangeDetectorRef,
        private snackBar: MatSnackBar) {

    }

    emailForm: FormGroup;

    ngOnInit() {
        this.emailForm = this.fb.group({
            email: [this.data.email, Validators.required]
        });
    }

    onSubmit() {
        const controls = this.emailForm.controls;
        /** check form */
        if (this.emailForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }
        console.log('insubmit');
        console.log(controls);
    }
}
