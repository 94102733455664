import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
   selector: 'angly-about',
   templateUrl: './about.component.html',
   styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

   /* Variables */
   data: any;
   services = [];
   about: any;
   team = [];
   contact: any;

   constructor(private pageTitleService: PageTitleService, private service: ChkService, private db: AngularFirestore) {

      /* Page title */
      this.pageTitleService.setTitle(' Know More About Us');

      /* Page subTitle */
      this.pageTitleService.setSubTitle(' Our latest news.');

      this.db.collection('services').get().toPromise().then(types => {
         types.docs.forEach(type => {
            this.services.push(type.data());
         });
      }).catch(err => {
         console.error(err.message);
      });

   }


   ngOnInit() {
   }


}
