import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
   selector: '[angly-subscribe]',
   templateUrl: './subscribe.component.html',
   styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

   @Input() subscribeFormClasses: any;

   /* Form name */
   subscribe: FormGroup;
   emailPattern: any = /\S+@\S+\.\S+/;

   constructor(private formBuilder: FormBuilder, private db: AngularFirestore) {
      this.subscribe = this.formBuilder.group({
         email: [null, [Validators.required, Validators.pattern(this.emailPattern)]]
      });
   }

   ngOnInit() {
   }

   subscribeNow(value: any) {
      if (this.subscribe.valid) {
         this.db.doc('settings/newsletter').get().toPromise().then(emails => {
            let mails = [];
            if (emails.exists) {
               mails = emails.data().emails;
            }
            console.log(mails);
            console.log(mails.indexOf(value.email));
            if (mails.indexOf(value.email) === 0) {
               console.error('exists');
            } else {
               mails.push(value.email);
               const data = { emails: mails };
               this.db.doc('settings/newsletter').set(data).then(d => {
                  console.log('saved successfully');
               }).catch(err => {
                  console.error(err.message);
               });
            }
         }).catch(err => {
            console.error(err.message);
         });
      } else {
         console.error('Error!');
      }
   }

}
