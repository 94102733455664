import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'angly-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

    /* Variables */
    products = [];
    type: string;


    constructor(private service: ChkService, private db: AngularFirestore, private route: ActivatedRoute) {
        this.route.paramMap.subscribe(params => {
            this.type = params.get('product');
            this.db.collection('types').ref.where('type', '==', params.get('product')).orderBy('order', 'asc').get().then(products => {
              products.forEach(p => {
                  this.products.push(p.data());
              });
              console.log(this.products);
            }).catch(err => {
              console.error(err.message);
            });
        });
    }

    ngOnInit() {
    }

}
